var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"header-props":_vm.headerProps,"items":_vm.items,"search":_vm.search,"hide-default-header":"","hide-default-footer":!_vm.footer,"footer-props":{
    'items-per-page-options': [10, 20, 25, 50],
    'items-per-page-text': _vm.$t('table_rows_per_page')
  },"items-per-page":50,"pageCount":_vm.numberOfPages,"server-items-length":_vm.totalRecords,"disable-pagination":_vm.disablePagination,"no-data-text":_vm.$t('table_no_data_text')},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"header",fn:function(ref){
  var props = ref.props;
  var on = ref.on;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.name,class:[
            _vm.getSortableClass(header.sortable),
            _vm.getAlignClass(header.align)
          ],style:(header.width ? 'width: ' + header.width + 'px' : ''),attrs:{"role":"columnheader","aria-label":header.text},on:{"click":function($event){_vm.items.length > 1 ? on.sort(header.value) : ''}}},[_c('span',[_vm._v(_vm._s(header.text))]),(header.sortable !== false)?_c('div',{staticClass:"sort-icon-container"},[_c('v-icon',{staticClass:"sort-icon asc",class:props.options.sortDesc[0] &&
                  props.options.sortBy[0] === header.value &&
                  'selected',attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]),_c('v-icon',{staticClass:"sort-icon desc",class:!props.options.sortDesc[0] &&
                  props.options.sortBy[0] === header.value &&
                  'selected',attrs:{"size":"16"}},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()])}),0)])]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }